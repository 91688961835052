import { Badge } from "@/components/ui/shadcn/badge";
import { Card, CardContent } from "@/components/ui/shadcn/card";
import { cn } from "@/lib/utils";
import Guard from "@/components/Guard";

interface TournamentStatusBadgeProps {
  status: 'upcoming' | 'in_progress' | 'complete' | 'archived';
  visibility: 'draft' | 'published';
  variant?: 'badge' | 'card';
}

function TournamentStatusBadge({
                                 status,
                                 visibility,
                                 variant = 'badge'
                               }: TournamentStatusBadgeProps) {
  const getStatusBadge = () => {
    switch (status) {
      case 'upcoming':
        return {
          label: 'Upcoming',
          className: "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200"
        };
      case 'in_progress':
        return {
          label: 'In Progress',
          className: "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"
        };
      default:
        return null;
    }
  };

  const getDraftBadge = () => ({
    label: 'Draft',
    className: "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200"
  });

  const renderBadges = () => {
    const badges = [];

    // Always show status badge first if it's upcoming or in progress
    const statusBadge = getStatusBadge();
    if (statusBadge) {
      badges.push(
        <Badge key="status" className={cn("ml-2", statusBadge.className)}>
          {statusBadge.label}
        </Badge>
      );
    }

    // Show draft badge to admins if in draft
    if (visibility === 'draft') {
      const draftBadge = getDraftBadge();
      badges.push(
        <Guard key="draft" requiresRegionAdmin>
          <Badge className={cn("ml-2", draftBadge.className)}>
            {draftBadge.label}
          </Badge>
        </Guard>
      );
    }

    return badges;
  };

  const badges = renderBadges();
  if (badges.length === 0) return null;

  if (variant === 'card') {
    return (
      <Card>
        <CardContent className="p-4">
          <div className="space-y-1">
            <p className="text-sm font-medium text-gray-500">Status</p>
            <div className="flex items-center">
              {badges}
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  return <div className="flex items-center">{badges}</div>;
}

export default TournamentStatusBadge;
