import React from 'react';
import {CircleHelp} from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/shadcn/dialog";
import { Button } from "@/components/ui/shadcn/button";

const PointSystemInfo = () => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" size="sm" className="p-0 h-auto">
          <CircleHelp className="h-5 w-5 text-muted-foreground hover:text-warhammer-gold transition-colors" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Ranking Point System</DialogTitle>
          <DialogDescription>
            Understanding how points are calculated for the rankings.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <p>The ranking system is based on the following criteria:</p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Only the top 3 results for each player in the current season are considered.</li>
            <li>Tournaments must have 10+ players to be eligible.</li>
            <li>Tournaments must have 3+ rounds to be eligible.</li>
            <li>The maximum points possible per tournament is 80-100 based on 3,4 or 5+ rounds.</li>
          </ul>
          <p>Point calculation:</p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Base points are determined by the number of players and points cap at 100</li>
            <li>A multiplier is applied based on the number of rounds:
              <ul className="list-disc pl-5 mt-2">
                <li>3 rounds: 0.8x</li>
                <li>4 rounds: 0.9x</li>
                <li>5+ rounds: 1.0x</li>
              </ul>
            </li>
            <li>Points are then adjusted based on the player's final ranking in the tournament.</li>
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PointSystemInfo;
