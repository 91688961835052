import React, { useState } from 'react';
import {Link, usePage} from '@inertiajs/react';
import TournamentForm from "@/components/ui/tournaments/TournamentForm";
import Guard from "@/components/Guard";
import PageHeader from "@/components/layouts/PageHeader";
import PageContainer from "@/components/layouts/PageContainer";
import { Button } from "@/components/ui/controls/Button";
import TournamentDataTable from "@/components/ui/tournaments/index/TournamentDataTable";

interface TournamentActionsProps {
  onAdd: () => void;
}

const TournamentActions = ({ onAdd }: TournamentActionsProps) => {
  const { current_region } = usePage().props;

  return (
    <Guard requiresRegionAdmin>
      <div className="flex items-center gap-2">
        <Link href={`/${current_region.slug}/tournaments/import`}>
          <Button variant="secondary" icon="Download">
            Import Tournament
          </Button>
        </Link>
        <Button icon="Plus" onClick={onAdd}>
          Add Tournament
        </Button>
      </div>
    </Guard>
  );
};

const Index = () => {
  const { tournaments } = usePage<PageProps>().props;
  const [isCreating, setIsCreating] = useState(false);

  return (
    <>
      <PageContainer
        header={<PageHeader showSeasonSelector={true} />}
        actions={<TournamentActions onAdd={() => setIsCreating(true)} />}
      >
        <TournamentDataTable data={tournaments} />
      </PageContainer>

      <TournamentForm
        isOpen={isCreating}
        onClose={() => setIsCreating(false)}
      />
    </>
  );
};

export default Index;
