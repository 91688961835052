import React from 'react';
import {usePage, useForm} from '@inertiajs/react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/shadcn/table";
import { Button } from "@/components/ui/shadcn/button";
import PlayerName from "@/components/ui/players/PlayerNameLink";
import Guard from "@/components/Guard.tsx";
import {Edit, Loader2, UserSearch} from 'lucide-react';
import PageHeader from "@/components/layouts/PageHeader.tsx";
import PageContainer from "@/components/layouts/PageContainer.tsx";

interface Player {
  id: number;
  first_name: string;
  last_name: string;
  nickname: string;
  full_name: string;
}

interface PageProps {
  players: Player[];
}

const Index = () => {
  const { players } = usePage<PageProps>().props;
  const { processing, get } = useForm();

  const handleDetectDuplicates = () => {
    get('/players/duplicates');
  };

  return (
    <PageContainer header={
      <PageHeader>
      </PageHeader>
    }
    actions={
      <Guard requiresRegionAdmin={true}>
        <Button
          onClick={handleDetectDuplicates}
          disabled={processing}
          className="flex items-center space-x-2"
        >
          {processing ? (
            <Loader2 className="w-4 h-4 animate-spin" />
          ) : (
            <UserSearch className="w-4 h-4" />
          )}
          <span>{processing ? 'Detecting...' : 'Detect Duplicates'}</span>
        </Button>
      </Guard>
    }
    >
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Full Name</TableHead>
            <TableHead>Nickname</TableHead>
            <TableHead className="text-right">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {players.map((player) => (
            <TableRow key={player.id}>
              <TableCell><PlayerName player={player} showRegion={true}/></TableCell>
              <TableCell>{player.nickname || '-'}</TableCell>
              <TableCell className="text-right">
                <Guard requiresSuperAdmin={true}>
                  <Button variant={"ghost"}
                          onClick={() => setEditingPlayer(player)}
                          className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors duration-200"
                  >
                    <Edit className="h-4 w-4" />
                  </Button>
                </Guard>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PageContainer>
  );
};

export default Index;
