import React, {useState} from 'react';
import {useForm, usePage} from '@inertiajs/react';
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "@/components/ui/shadcn/table";
import {Edit} from "lucide-react";
import {Badge} from "@/components/ui/shadcn/badge";
import TournamentResultDialog from "@/components/ui/tournaments/TournamentResultDialog";
import PlayerName from "@/components/ui/players/PlayerNameLink";
import DeleteButton from "@/components/ui/controls/DeleteButton";
import TournamentForm from "@/components/ui/tournaments/TournamentForm";
import Guard from "@/components/Guard";
import TournamentUrlCard from "@/components/ui/tournaments/common/TournamentUrlCard";
import TournamentStatusBadge from "@/components/ui/tournaments/common/TournamentStatusBadge";
import PageHeader from "@/components/layouts/PageHeader";
import PageContainer from "@/components/layouts/PageContainer";
import {Button} from "@/components/ui/controls/Button";
import {ResyncButton} from "@/components/ui/tournaments/show/ResyncButton";
import TournamentInfoBar from "@/components/ui/tournaments/show/TournamentInfoBar";

interface TournamentResult {
  id: number;
  rank: number;
  player: {
    id: number;
    full_name: string;
    nickname?: string;
  };
  faction: {
    name: string;
  } | null;
  wins: number;
  losses: number;
  draws: number;
  score: number;
  notice?: 'edited' | 'warning';
  achievements: Array<{ id: number; emoji: string; label: string }>;
}

export interface Tournament {
  id: number;
  name: string;
  url?: string;
  start_date: string;
  end_date?: string;
  state: string;
  rounds: number;
  platform: string;
  platform_url: string;
  tournament_results: TournamentResult[];
  player_cap: number;
  featured: boolean;
  contact_email?: string;
}

interface PageProps {
  tournament: Tournament;
}

const TournamentShowActions = ({onEdit, tournament}) => {
  return (
    <Guard requiresRegionAdmin>
      <div className="flex items-center gap-2">
        <Button
          onClick={() => onEdit(true)}
          variant="outline"
          size="sm"
          className="flex items-center"
          icon="Edit"
        >
          Edit Tournament
        </Button>
      </div>
    </Guard>
  );
};

const Show = () => {
  const {tournament} = usePage<PageProps>().props;
  const [editingResult, setEditingResult] = useState<TournamentResult | null>(null);
  const [isCreatingResult, setIsCreatingResult] = useState(false);
  const [isEditingTournament, setIsEditingTournament] = useState(false);
  const {delete: destroyTournament} = useForm();

  const sortedResults = tournament.tournament_results.sort((a, b) => {
    // If both ranks are null, maintain original order
    if (a.rank === null && b.rank === null) return 0;
    // If only a's rank is null, b should come first
    if (a.rank === null) return 1;
    // If only b's rank is null, a should come first
    if (b.rank === null) return -1;
    // If neither rank is null, sort normally
    return a.rank - b.rank;
  });

  const getRankEmoji = (rank: number) => {
    if (rank === null || rank === undefined) return '-';

    switch (rank) {
      case 1:
        return '🥇';
      case 2:
        return '🥈';
      case 3:
        return '🥉';
      default:
        return `${rank}.`;
    }
  };

  const getNoticeBadge = (notice?: 'edited' | 'warning') => {
    if (!notice) return null;

    const badgeProps = {
      edited: {variant: 'secondary' as const, children: 'Edited'},
      warning: {variant: 'destructive' as const, children: 'Warning'},
    }[notice];

    return (
      <Guard requiresRegionAdmin>
        <Badge className="ml-2" {...badgeProps} />
      </Guard>
    )
  };

  const handleDelete = () => {
    destroyTournament(`/au/tournaments/${tournament.id}`, {
      preserveState: false,
      preserveScroll: false,
    });
  };

  const handleCreateResult = () => {
    setIsCreatingResult(true);
  };

  return (
    <>
      <PageContainer header={
        <PageHeader>
          <div className="flex items-center gap-4">
            <TournamentStatusBadge status={tournament.status} visibility={tournament.visibility} variant={'card'}/>
            {tournament.url && <TournamentUrlCard url={tournament.url}/>}
          </div>
        </PageHeader>
        }
        actions={
          <TournamentShowActions onEdit={setIsEditingTournament} tournament={tournament}/>
        }
        details={
          <TournamentInfoBar tournament={tournament} />
        }
        >
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Rank</TableHead>
              <TableHead className="font-bold">Ranking Points</TableHead>
              <TableHead>Player</TableHead>
              <TableHead>Faction</TableHead>
              <TableHead className="text-right">Wins</TableHead>
              <TableHead className="text-right">Losses</TableHead>
              <TableHead className="text-right">Draws</TableHead>
              <TableHead className="text-right">Achievements</TableHead>
              <TableHead className="text-right">Points</TableHead>
              <Guard requiresRegionAdmin>
                <TableHead className="w-[50px]">Edit</TableHead>
              </Guard>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedResults.map((result) => (
              <TableRow key={result.id} className={result.rank <= 3 && result.rank >= 1 ? 'font-semibold' : ''}>
                <TableCell className="font-medium">{getRankEmoji(result.rank)}</TableCell>
                <TableCell className="font-bold">{result.ranking_points}</TableCell>
                <TableCell>
                  <PlayerName player={result.player}/>
                  {getNoticeBadge(result.notice)}
                </TableCell>
                <TableCell>{result.faction?.name || 'Unknown'}</TableCell>
                <TableCell className="text-right text-green-600 dark:text-green-400">{result.wins}</TableCell>
                <TableCell className="text-right text-red-600 dark:text-red-400">{result.losses}</TableCell>
                <TableCell className="text-right text-orange-600 dark:text-orange-400">{result.draws}</TableCell>
                <TableCell className="text-right">
                  <div className="flex items-center justify-end space-x-1">
                    {result.achievements?.map((achievement) => (
                      <span key={achievement.id} title={achievement.label}>
                          {achievement.emoji}
                        </span>
                    ))}
                  </div>
                </TableCell>
                <TableCell className="text-right">{result.score}</TableCell>
                <Guard requiresRegionAdmin>
                  <TableCell>
                    <Button variant={"ghost"}
                            onClick={() => setEditingResult(result)}
                            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors duration-200"
                    >
                      <Edit className="h-4 w-4"/>
                    </Button>
                  </TableCell>
                </Guard>
              </TableRow>
            ))
            }
          </TableBody>
        </Table>

        <Guard requiresRegionAdmin>
          <div className="flex justify-between items-center mt-8">
            <DeleteButton
              onDelete={handleDelete}
              initialText="Delete Tournament"
              confirmText="Are you sure? This will delete all associated data."
            />
            <div className="flex gap-4"> {/* Added gap between right-side buttons */}
              {tournament.external_id && (
                <ResyncButton
                  platformId={tournament.external_id.tournament_platform.identifier}
                  tournamentId={tournament.external_id.value}
                  platformName={tournament.external_id.tournament_platform.name}
                />
              )}
              <Button onClick={handleCreateResult} icon="Plus">
                Add Result
              </Button>
            </div>
          </div>
        </Guard>
      </PageContainer>


      {editingResult && (
        <TournamentResultDialog
          isOpen={!!editingResult}
          onClose={() => setEditingResult(null)}
          tournament_result={editingResult}
        />
      )}

      {isCreatingResult && (
        <TournamentResultDialog
          isOpen={isCreatingResult}
          onClose={() => setIsCreatingResult(false)}
          tournament_result={{
            id: 0,
            rank: 0,
            wins: 0,
            losses: 0,
            draws: 0,
            score: 0,
            player: {id: 0, full_name: ''},
            faction: null,
          }}
          isCreating={true}
        />
      )}

      {isEditingTournament && (
        <TournamentForm
          isOpen={isEditingTournament}
          onClose={() => setIsEditingTournament(false)}
          tournament={tournament}
        />
      )}

    </>

  );
};

export default Show;
