import { ColumnDef } from '@tanstack/react-table';
import { Star } from 'lucide-react';
import { Tournament } from '@/types/Tournament.types';
import FormattedDate from "@/components/ui/FormattedDate";
import TournamentStatusBadge from "@/components/ui/tournaments/common/TournamentStatusBadge";
import { PlatformLink } from "@/components/ui/controls/PlatformLink";
import { TournamentLink } from "@/components/ui/tournaments/TournamentLink";

// Define more specific types for better type safety
interface DateValue {
  start_date: string;
  end_date?: string;
}

interface NameValue {
  id: number;
  name: string;
  featured: boolean;
  visibility: 'draft' | 'published';
}

interface PlatformValue {
  platform: string;
  platform_url: string;
}

export const columns: ColumnDef<Tournament>[] = [
  {
    accessorFn: (row): DateValue => ({
      start_date: row.start_date,
      end_date: row.end_date,
    }),
    id: 'date',
    header: 'Date',
    cell: ({ getValue }) => {
      const value = getValue<DateValue>();
      return (
        <FormattedDate
          startDate={value.start_date}
          endDate={value.end_date}
        />
      );
    },
  },
  {
    accessorFn: (row): NameValue => ({
      id: row.id,
      name: row.name,
      featured: row.featured,
      visibility: row.visibility,
    }),
    id: 'name',
    header: 'Name',
    filterFn: (row, columnId, filterValue) => {
      const value = row.getValue(columnId) as NameValue;
      return value.name.toLowerCase().includes(String(filterValue).toLowerCase());
    },
    cell: ({ getValue, row }) => {
      const value = getValue<NameValue>();
      return (
        <div className="flex items-center space-x-2">
          {value.featured && (
            <Star className="h-4 w-4 text-warhammer-gold fill-warhammer-gold" />
          )}
          <TournamentLink tournament={row.original} />
          <TournamentStatusBadge
            status={row.original.status}
            visibility={value.visibility}
          />
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.address,
    id: 'location',
    header: 'Location',
    filterFn: (row, columnId, filterValue) => {
      const address = row.original.address;
      if (!address || !filterValue) return false;
      return address.state?.toLowerCase() === String(filterValue).toLowerCase();
    },
    cell: ({ getValue }) => {
      const address = getValue<Tournament['address']>();
      if (!address) return <span className="text-muted-foreground">—</span>;

      const location = [address.city, address.state].filter(Boolean).join(", ");

      if (address.latitude && address.longitude) {
        return (
          <a
            href={`https://www.google.com/maps?q=${address.latitude},${address.longitude}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-foreground hover:underline"
          >
            {location}
          </a>
        );
      }

      return <span className="text-muted-foreground">{location || "—"}</span>;
    },
  },
  {
    accessorFn: (row): PlatformValue => (row),
    id: 'platform',
    header: 'Platform',
    cell: ({ getValue }) => {
      const value = getValue<PlatformValue>();
      return <PlatformLink tournament={value} />;
    },
  },
  {
    accessorKey: 'player_count',
    header: 'Players / Cap',
    cell: ({ row }) => {
      const tournament = row.original;
      const playerCount = tournament.player_count;
      const playerCap = tournament.player_cap;

      if (playerCap === 0) {
        return <span>{playerCount}</span>;
      }

      return <span>{playerCount} / {playerCap}</span>;
    },
  },
];
