import React, { useState } from 'react';
import { Input } from "@/components/ui/shadcn/input";
import { router } from "@inertiajs/react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/shadcn/select";
import LoadingButton from "@/components/ui/controls/LoadingButton";
import { PLATFORM_CONFIGS } from "@/components/ui/tournaments/common/PlatformConfigs";
import { useToast } from "@/components/ui/shadcn/hooks/use-toast";

const PLATFORM_URL_PATTERNS = {
  'tp-new-recruit': {
    domain: 'newrecruit.eu',
    idPattern: /^[a-z0-9]+$/i,  // Updated to match alphanumeric characters
    exampleId: '66d240a94db39a05bbd25ee2',
    exampleUrl: 'https://www.newrecruit.eu/app/tournydetails/rankings?id=66d240a94db39a05bbd25ee2'
  },
  'tp-bcp': {
    domain: 'bestcoastpairings.com',
    idPattern: /^[A-Za-z0-9]{10,16}$/,
    exampleId: 'GP2ixHjdk4ZQ',
    exampleUrl: 'https://www.bestcoastpairings.com/event/MA8BDED4FT'
  },
  'tp-stats-and-ladders': {
    domain: 'statsandladders.com',
    idPattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i,
    exampleId: 'a4c894b8-d284-45ca-bdbb-3f3c927f24b6',
    exampleUrl: 'https://my.statsandladders.com/tournament/show/a4c894b8-d284-45ca-bdbb-3f3c927f24b6'
  }
} as const;

type PlatformId = keyof typeof PLATFORM_URL_PATTERNS;

const ImportTournamentForm = () => {
  const [tournamentId, setTournamentId] = useState('');
  const [platform, setPlatform] = useState<PlatformId>('tp-new-recruit');
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const getPlaceholder = (platformId: PlatformId) => {
    const config = PLATFORM_CONFIGS[platformId];
    const pattern = PLATFORM_URL_PATTERNS[platformId];
    return `Enter ${config.displayName} tournament ID or paste URL`;
  };

  const getHelpText = (platformId: PlatformId) => {
    const pattern = PLATFORM_URL_PATTERNS[platformId];
    return (
      <div className="text-sm text-muted-foreground mt-1">
        <p>Examples:</p>
        <p className="mt-1">ID: <span className="font-mono">{pattern.exampleId}</span></p>
        <p className="mt-1">URL: <span className="font-mono break-all">{pattern.exampleUrl}</span></p>
      </div>
    );
  };

  const validateInput = (value: string, platformId: PlatformId) => {
    if (!value) return "Tournament ID or URL is required";

    const pattern = PLATFORM_URL_PATTERNS[platformId];

    // Check if it's a URL
    if (value.includes(pattern.domain)) {
      return null; // Let the backend handle URL parsing
    }

    // Check if it's a valid ID format
    if (!value.match(pattern.idPattern)) {
      return `Please enter a valid ${PLATFORM_CONFIGS[platformId].displayName} tournament ID or URL`;
    }

    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const error = validateInput(tournamentId, platform);
    if (error) {
      toast({
        variant: "destructive",
        title: "Validation Error",
        description: error
      });
      return;
    }

    setIsLoading(true);
    router.post('/import', {
      tournament_id: tournamentId,
      platform: platform,
    }, {
      onFinish: () => setIsLoading(false),
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 py-6">
      <div className="flex flex-col space-y-2">
        <label htmlFor="platform" className="font-medium">Platform</label>
        <Select
          value={platform}
          onValueChange={(value: PlatformId) => setPlatform(value)}
        >
          <SelectTrigger id="platform">
            <SelectValue placeholder="Select platform" />
          </SelectTrigger>
          <SelectContent>
            {Object.entries(PLATFORM_CONFIGS).map(([identifier, config]) => (
              <SelectItem key={identifier} value={identifier}>
                <div className="flex items-center gap-2">
                  <img
                    src={config.logo}
                    alt={`${config.displayName} logo`}
                    className={config.logoClass}
                  />
                  <span>{config.displayName}</span>
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div>
        <Input
          type="text"
          value={tournamentId}
          onChange={(e) => setTournamentId(e.target.value)}
          placeholder={getPlaceholder(platform)}
          disabled={isLoading}
        />
        {getHelpText(platform)}
      </div>

      <LoadingButton
        type="submit"
        loading={isLoading}
        loadingText="Importing..."
      >
        Import Tournament
      </LoadingButton>
    </form>
  );
};

export default ImportTournamentForm;
