import React from 'react';
import { ExternalLink } from 'lucide-react';
import { SocialIcon } from 'react-social-icons';
import { PLATFORM_CONFIGS, PlatformIdentifier } from '@/components/ui/tournaments/common/PlatformConfigs';

interface PlatformLinkProps {
  tournament: {
    platform?: string;
    platform_name?: string;
    platform_url?: string;
    url?: string;
    status?: string;
  };
}

export function PlatformLink({ tournament }: PlatformLinkProps) {
  const isUpcoming = tournament.status === 'upcoming';
  const linkUrl = tournament.url || tournament.platform_url;

  // If no URL at all, just show platform name
  if (!linkUrl) {
    return (
      <span className="text-muted-foreground">
        {tournament.platform_name || 'Manual Entry'}
      </span>
    );
  }

  const linkText = isUpcoming ? 'Register' : 'Visit Site';

  // Get platform config if it exists
  const platformConfig = tournament.platform ?
    PLATFORM_CONFIGS[tournament.platform as PlatformIdentifier] :
    undefined;

  // Try to render social icon if not a platform URL
  if (!platformConfig && tournament.url) {
    try {
      return (
        <a
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center gap-2 text-foreground hover:text-foreground/80 hover:underline"
        >
          <SocialIcon
            url={linkUrl}
            style={{ height: 25, width: 25 }}
          />
          <span>{linkText}</span>
        </a>
      );
    } catch {
      // If social icon fails, fall back to generic link
      return (
        <a
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center gap-2 text-foreground hover:text-foreground/80 hover:underline"
        >
          <ExternalLink className="h-5 w-5" />
          <span>{linkText}</span>
        </a>
      );
    }
  }

  // Handle platform-specific rendering
  if (platformConfig) {
    return (
      <a
        href={linkUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-flex items-center gap-2 text-foreground hover:text-foreground/80 hover:underline"
      >
        <img
          src={platformConfig.logo}
          alt={`${platformConfig.displayName} logo`}
          className={platformConfig.logoClass}
        />
        <span>
          {isUpcoming ? 'Register' : tournament.platform_name}
        </span>
      </a>
    );
  }

  // Fallback for any other case
  return (
    <span className="text-muted-foreground">
      {tournament.platform_name || 'Manual Entry'}
    </span>
  );
}
