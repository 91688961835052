import React from 'react';
import { Link } from '@inertiajs/react';
import { cn } from "@/lib/utils";

interface SidebarItemProps {
  icon: React.ElementType;
  label: string;
  href: string;
  isCollapsed: boolean;
  onClick?: () => void;
}

const SidebarItem = ({
                       icon: Icon,
                       label,
                       href,
                       isCollapsed,
                       onClick
                     }: SidebarItemProps) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Link
      href={href}
      className={cn(
        "flex items-center w-full px-4 py-2 text-warhammer-gold hover:text-white hover:bg-warhammer-red-dark/30 transition-colors duration-200",
        isCollapsed ? "justify-center" : "justify-start"
      )}
      onClick={handleClick}
    >
      <Icon className={cn("h-5 w-5", isCollapsed ? "mx-auto" : "mr-2")} />
      {!isCollapsed && <span>{label}</span>}
    </Link>
  );
};

export default SidebarItem;
