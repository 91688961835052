import {Link, usePage} from '@inertiajs/react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/shadcn/card";
import RankingsTable from "@/components/ui/rankings/RankingsTable.tsx";
import UpcomingTournamentsCard from "@/components/ui/dashboard/UpcomingTournamentsCard.tsx";
import PageHeader from "@/components/layouts/PageHeader.tsx";
import FactionLeadersTable from "@/components/ui/dashboard/FactionLeadersTable.tsx";
import FactionAppearanceChart from "@/components/ui/dashboard/FactionAppearanceChart.tsx";
import FactionWinRateChart from "@/components/ui/dashboard/FactionWinRateChart.tsx";
import {useLinkPlayerPrompt} from "@/hooks/useLinkPlayerPrompt.ts";
import LinkPlayerPromptDialog from "@/components/ui/profile/LinkPlayerPromptDialog.tsx";
import SurveyPromptDialog from "@/components/ui/dashboard/SurveyPromptDialog.tsx";
import {useSurveyPrompt} from "@/hooks/useSurveyPrompt.ts";
import {Tournament} from "@/types/Tournament.types.ts";

interface PageProps {
  rankings: RankingData[];
  totalTournaments: number;
  totalPlayers: number;
  factionStats: { name: string; count: number; winRate: number }[];
  upcomingTournaments: Tournament[];

}

const Index = () => {
  const { rankings, totalTournaments, totalPlayers, factionStats, upcomingTournaments, current_region, current_season, factions } = usePage<PageProps>().props;
  const { overall_rankings, faction_rankings } = rankings;
  const { showPrompt, closePrompt } = useLinkPlayerPrompt();
  const { showSurveyPrompt, closeSurveyPrompt } = useSurveyPrompt();

  const topFiveRankings = overall_rankings.slice(0, 5);

  return (
    <div className="p-4 md:p-6 space-y-6">
      <PageHeader title={`${current_season.name} - ${current_region.name} Region`} showSeasonSelector={true} />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-semibold flex items-center">
              <span className="text-2xl mr-2">🏟️</span>
              Total Tournaments
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-3xl font-bold">{totalTournaments}</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-semibold flex items-center">
              <span className="text-2xl mr-2">🤺</span>
              Total Players
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-3xl font-bold">{totalPlayers}</p>
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>
              <Link href={`/${current_region.slug}/rankings`} className="text-xl font-semibold">
                <h2 className="text-2xl font-semibold">Top 5 Players</h2>
              </Link>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <RankingsTable rankings={topFiveRankings} showDetailedView={false}/>
          </CardContent>
        </Card>

        <UpcomingTournamentsCard tournaments={upcomingTournaments}/>

        <FactionAppearanceChart
          factionStats={factionStats}
          factions={factions}
        />

        <FactionWinRateChart factionStats={factionStats} factions={factions} />

        {/*<Card>*/}
        {/*  <CardHeader>*/}
        {/*    <CardTitle>Featured Players</CardTitle>*/}
        {/*  </CardHeader>*/}
        {/*  <CardContent>*/}
        {/*    <div className="text-center py-8">*/}
        {/*      <p className="text-4xl mb-4">🎭</p>*/}
        {/*      <p className="text-xl font-semibold">Coming Soon!</p>*/}
        {/*      <p className="text-muted-foreground">Legendary players will be showcased here.</p>*/}
        {/*    </div>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}

        {/*<Card>*/}
        {/*  <CardHeader>*/}
        {/*    <CardTitle>Recent Battle Reports</CardTitle>*/}
        {/*  </CardHeader>*/}
        {/*  <CardContent>*/}
        {/*    <div className="text-center py-8">*/}
        {/*      <p className="text-4xl mb-4">📜</p>*/}
        {/*      <p className="text-xl font-semibold">Coming Soon!</p>*/}
        {/*      <p className="text-muted-foreground">Epic battle tales will be shared here.</p>*/}
        {/*    </div>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}

        <div className="col-span-full">
          <FactionLeadersTable
            factionRankings={faction_rankings}
            factions={factions}
          />
        </div>
      </div>
      <LinkPlayerPromptDialog
        isOpen={showPrompt}
        onClose={closePrompt}
      />
      <SurveyPromptDialog
        isOpen={showSurveyPrompt}
        onClose={closeSurveyPrompt}
      />
    </div>
  );
};

export default Index;
