import React from 'react';
import {Head, usePage} from '@inertiajs/react';
import { Card, CardHeader, CardContent } from "@/components/ui/shadcn/card";
import RankingsTable from '@/components/ui/rankings/RankingsTable';
import {SeasonSelector} from "@/components/ui/SeasonSelector.tsx";
import {format} from "date-fns";
import AchievementsLegend from "@/components/ui/rankings/AchievementsLegend.tsx";
import PageHeader from "@/components/layouts/PageHeader.tsx";

interface RankingData {
  id: number;
  name: string;
  factions: string[];
  totalPoints: number;
  lastThree: number[];
  achievements: string[];
}

interface Achievement {
  emoji: string;
  label: string;
}

interface PageProps {
  rankings: RankingData[];
  achievements: Achievement[];
}

const Index = () => {
  const { rankings, achievements, current_season } = usePage<PageProps>().props;
  const { overall_rankings } = rankings;

  const formatDateRange = (start: string, end: string) => {
    return `${format(new Date(start), 'MMMM d, yyyy')} - ${format(new Date(end), 'MMMM d, yyyy')}`;
  };

  return (
    <div className="p-4 md:p-6 space-y-6">
      <PageHeader title={`Masters Rankings`} showSeasonSelector={true} />
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <div className="space-y-1">
              <h2 className="text-2xl font-semibold">{current_season.name} Masters Rankings</h2>
              <p className="text-sm text-muted-foreground">
                {formatDateRange(current_season.start_date, current_season.end_date)}
              </p>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <RankingsTable rankings={overall_rankings} showDetailedView={true}/>
        </CardContent>
      </Card>
    </div>
  );
};

export default Index;
