import React, {useCallback, useEffect, useState} from 'react';
import { Input } from "@/components/ui/shadcn/input";
import { DataTable } from "@/components/ui/controls/tables/DataTable";
import { columns } from "./TournamentTableColumns";
import { Tournament } from "@/types/Tournament.types";
import { fuzzyFilter } from "./useTableSearch";
import { StateFilter } from "@/components/ui/tournaments/index/StateFilter";
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable
} from "@tanstack/react-table";
import { debounce } from 'lodash';

export default function TournamentDataTable({ data }: { data: Tournament[] }) {
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);

  // const handleSearch = useCallback(
  //   debounce((value: string) => {
  //     setGlobalFilter(value);
  //   }, 100),
  //   []
  // );

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // filterFns: {
    //   fuzzy: fuzzyFilter,
    // },
    // // Enable global filtering
    // globalFilterFn: fuzzyFilter,
  });
  // useEffect(() => {
  //   console.log('Filtered rows:', table.getFilteredRowModel().rows.length);
  // }, [table.getFilteredRowModel().rows]);
  //
  // useEffect(() => {
  //   console.log(`Column filters:`, columnFilters);
  // }, [columnFilters]);

  const getRowClassName = (row: Tournament) => {
    return row.status === 'upcoming' ? "bg-muted/50 dark:bg-muted/20" : "";
  };

  const showDivider = (currentRow: Tournament, previousRow?: Tournament) => {
    return !!previousRow && previousRow.status === 'upcoming' && currentRow.status !== 'upcoming';
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        {/*<Input*/}
        {/*  placeholder="Search tournaments..."*/}
        {/*  value={globalFilter ?? ''}*/}
        {/*  onChange={(event) => handleSearch(event.target.value)}*/}
        {/*  className="w-[300px]"*/}
        {/*/>*/}
        <span>Filter by: </span>
        <StateFilter table={table} data={data} />
      </div>
      <DataTable
        columns={columns}
        getRowClassName={getRowClassName}
        showDivider={showDivider}
        table={table}
      />
    </div>
  );
}
