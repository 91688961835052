import {Link, usePage} from '@inertiajs/react';
import { Card, CardHeader, CardContent } from "@/components/ui/shadcn/card";
import { Calendar, Star, ChevronRight } from 'lucide-react';
import { PlatformLink } from "@/components/ui/controls/PlatformLink";
import FormattedDate from "@/components/ui/FormattedDate";
import {TournamentLink} from "@/components/ui/tournaments/TournamentLink.tsx";

interface Tournament {
  id: number;
  name: string;
  start_date: string;
  end_date?: string;
  platform: string;
  platform_url: string;
  featured: boolean;
  player_count: number;
  spots_remaining?: number;
  player_cap: number;
}

interface UpcomingTournamentsCardProps {
  tournaments: Tournament[];
}

const TournamentRow = ({ tournament }: { tournament: Tournament }) => {
  const { current_region } = usePage<PageProps>().props;
  const isFeatured = tournament.featured;

  const renderPlayerCount = () => {
    if (tournament.player_cap === 0) {
      return `${tournament.player_count} players`;
    }

    return tournament.spots_remaining === 0
      ? 'Full'
      : `${tournament.spots_remaining} spots left`;
  };

  return (
    <div className={`
      relative p-4 mb-2 rounded-lg transition-all
      ${isFeatured ? 'bg-warhammer-gold/10 border border-warhammer-gold' : 'hover:bg-muted/50'}
    `}>
      {isFeatured && (
        <div className="absolute -left-1 top-1/2 -translate-y-1/2 w-2 h-16 bg-warhammer-gold rounded-r-full" />
      )}

      <div className="flex items-center justify-between">
        <div className="flex-1">
          <div className="flex items-center">
            {isFeatured && (
              <Star className="h-4 w-4 text-warhammer-gold mr-2 fill-warhammer-gold" />
            )}
            <TournamentLink tournament={tournament} />
          </div>

          <div className="flex items-center mt-1 text-sm text-muted-foreground">
            <Calendar className="h-4 w-4 mr-2" />
            <FormattedDate startDate={tournament.start_date} endDate={tournament.end_date} />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <div className="text-right">
            <div className="text-sm font-medium">
              {renderPlayerCount()}
            </div>
            <div className="text-sm text-muted-foreground">
              <PlatformLink tournament={tournament} />
            </div>
          </div>
          <ChevronRight className="h-5 w-5 text-muted-foreground" />
        </div>
      </div>
    </div>
  );
};

const UpcomingTournamentsCard = ({ tournaments }: UpcomingTournamentsCardProps) => {
  const { current_region } = usePage<PageProps>().props;
  if (!tournaments || tournaments.length === 0) {
    return (
      <Card>
        <CardHeader>
          <Link href={`/${current_region.slug}/tournaments`} className="text-xl font-semibold">
            <h2 className="text-2xl font-semibold">Upcoming Tournaments</h2>
          </Link>
        </CardHeader>
        <CardContent>
          <div className="text-center py-8">
            <Calendar className="h-12 w-12 mx-auto mb-4 text-muted-foreground" />
            <p className="text-lg font-semibold">No upcoming tournaments</p>
            <p className="text-muted-foreground">Check back soon for new events</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  // Sort tournaments: featured first, then by date
  const sortedTournaments = [...tournaments].sort((a, b) => {
    if (a.featured && !b.featured) return -1;
    if (!a.featured && b.featured) return 1;
    return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
  }).slice(0, 5);

  return (
    <Card>
      <CardHeader>
        <Link href={'/au/tournaments'} className="text-xl font-semibold">
          <h2 className="text-2xl font-semibold">Upcoming Tournaments</h2>
        </Link>      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          {sortedTournaments.map((tournament) => (
            <TournamentRow key={tournament.id} tournament={tournament} />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default UpcomingTournamentsCard;
