import {createInertiaApp} from '@inertiajs/react'
import {createElement} from 'react'
import {createRoot} from 'react-dom/client'
import App from "@/pages/App.tsx";
import EmptyLayout from "@/components/layouts/EmptyLayout.tsx";

createInertiaApp({
  title: title => title ? `${title} | oldworldrankings.com` : 'oldworldrankings.com',

  progress: {
    // The delay after which the progress bar will appear, in milliseconds...
    delay: 100,

    // The color of the progress bar...
    color: '#FFD700',

    // Whether to include the default NProgress styles...
    includeCSS: true,

    // Whether the NProgress spinner will be shown...
    showSpinner: false,
  },
  resolve: (name) => {
    const pages = import.meta.glob('../pages/**/*.tsx', {eager: true})
    const page = pages[`../pages/${name}.tsx`]

    page.default.layout = page.default.layout ||
      (name.startsWith('auth/')
         ? (page) => createElement(EmptyLayout, null, page) :
        (page) => createElement(App, null, page))

    return page
  },

  setup({el, App, props}) {
    const root = createRoot(el)

    root.render(createElement(App, props))
  },
})

