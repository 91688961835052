import React from 'react';
import { useForm } from '@inertiajs/react';
import { Input } from "@/components/ui/shadcn/input";
import { Label } from "@/components/ui/shadcn/label";
import EditorDialog from "@/components/ui/forms/EditorDialog";
import { DatePicker } from "@/components/ui/controls/DatePicker";
import Toggle from "@/components/ui/controls/Toggle.tsx";
import { Card, CardContent } from "@/components/ui/shadcn/card";
import { ImageIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import AddressForm, { Address } from "@/components/ui/controls/AddressForm.tsx";

interface TournamentFormProps {
  isOpen: boolean;
  onClose: () => void;
  tournament?: {
    id?: number;
    name: string;
    rounds: number;
    start_date: string;
    end_date?: string;
    visibility: 'draft' | 'published';
    url?: string;
    player_cap?: number;
    featured?: boolean;
    contact_email?: string;
    address?: Address;
  };
}

function TournamentForm({ isOpen, onClose, tournament }: TournamentFormProps) {
  const { data, setData, post, patch, processing, errors, transform } = useForm({
    name: tournament?.name || '',
    rounds: tournament?.rounds || 3,
    start_date: tournament?.start_date ? new Date(tournament.start_date) : undefined,
    end_date: tournament?.end_date ? new Date(tournament.end_date) : undefined,
    url: tournament?.url || '',
    visibility: tournament?.visibility || 'draft',
    player_cap: tournament?.player_cap || 0,
    featured: tournament?.featured || false,
    contact_email: tournament?.contact_email || '',
    address: tournament?.address || {
      street: tournament?.address?.street || '',
      city: tournament?.address?.city || '',
      state: tournament?.address?.state || '',
      country: tournament?.address?.country || '',
      postal_code: tournament?.address?.postal_code || '',
      latitude: tournament?.address?.latitude || undefined,
      longitude: tournament?.address?.longitude || undefined,
      formatted_address: tournament?.address?.formatted_address || '',
    },
  });

  const handleStartDateChange = (date: Date | undefined) => {
    setData(prev => ({
      ...prev,
      start_date: date,
      end_date: !prev.end_date ? date : prev.end_date
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    transform((data) => ({
      tournament: { ...data }
    }));

    e.preventDefault();
    if (tournament?.id) {
      patch(`/au/tournaments/${tournament.id}`, {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
          onClose();
        },
      });
    } else {
      post('/au/tournaments', {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
          onClose();
        },
      });
    }
  };

  return (
    <EditorDialog
      isOpen={isOpen}
      onClose={onClose}
      title={tournament ? "Edit Tournament" : "Create Tournament"}
      onSubmit={handleSubmit}
      isProcessing={processing}
      className="max-w-[95vw] sm:max-w-[800px] max-h-[95vh] overflow-y-auto"
    >
      {/* Main content area */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-4">
        {/* Left Column - Tournament Details */}
        <div className="space-y-4">
          <Card>
            <CardContent className="pt-6 space-y-4">
              <div>
                <Label htmlFor="name">Tournament Name</Label>
                <Input
                  id="name"
                  value={data.name}
                  onChange={(e) => setData('name', e.target.value)}
                  className="mt-2"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Label htmlFor="rounds">Rounds</Label>
                  <Input
                    id="rounds"
                    type="number"
                    value={data.rounds}
                    onChange={(e) => setData('rounds', parseInt(e.target.value))}
                    className="mt-2"
                  />
                </div>

                <div>
                  <Label htmlFor="player_cap">Player Cap</Label>
                  <Input
                    id="player_cap"
                    type="number"
                    value={data.player_cap}
                    onChange={(e) => setData('player_cap', parseInt(e.target.value))}
                    className="mt-2"
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="start_date">Start Date</Label>
                <div className="mt-2">
                  <DatePicker date={data.start_date} setDate={handleStartDateChange} />
                </div>
              </div>

              <div>
                <Label htmlFor="end_date">End Date</Label>
                <div className="mt-2">
                  <DatePicker
                    date={data.end_date}
                    setDate={(date) => setData('end_date', date)}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="contact_email">Contact Email</Label>
                <Input
                  id="contact_email"
                  type="email"
                  value={data.contact_email}
                  onChange={(e) => setData('contact_email', e.target.value)}
                  className="mt-2"
                />
                {errors.contact_email && (
                  <p className="text-destructive text-sm mt-1">{errors.contact_email}</p>
                )}
              </div>

              <div>
                <Label htmlFor="url">Tournament URL</Label>
                <Input
                  id="url"
                  value={data.url}
                  onChange={(e) => setData('url', e.target.value)}
                  className="mt-2"
                />
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Right Column - Thumbnail and Settings */}
        <div className="space-y-4">
          {/* Thumbnail Card */}
          <Card>
            <CardContent className="pt-6">
              <div className="space-y-4">
                <Label>Tournament Thumbnail</Label>
                <div className="relative">
                  <div
                    className={cn(
                      "w-full h-[200px] rounded-lg border-2 border-dashed",
                      "flex items-center justify-center",
                      "bg-muted/10",
                      "cursor-not-allowed",
                      "border-muted"
                    )}
                  >
                    <div className="text-center space-y-1">
                      <ImageIcon className="w-8 h-8 mx-auto text-muted-foreground" />
                      <div className="text-sm text-muted-foreground">
                        Tournament thumbnails coming soon
                        <span className="block text-xs text-muted-foreground/80">
                          This feature is not yet available
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Settings Card */}
          <Card>
            <CardContent className="pt-6">
              <div className="space-y-6">
                <div>
                  <Label htmlFor="visibility">Visibility</Label>
                  <div className="mt-2">
                    <Toggle
                      checked={data.visibility === 'published'}
                      onCheckedChange={(value) => setData('visibility', value ? 'published' : 'draft')}
                      description="Draft tournaments are only visible to region administrators"
                      variant="primary"
                    />
                  </div>
                </div>

                <div>
                  <Label htmlFor="featured">Featured Tournament</Label>
                  <div className="mt-2">
                    <Toggle
                      checked={data.featured}
                      onCheckedChange={(checked) => setData('featured', checked)}
                      description="Show this tournament prominently on the dashboard"
                      variant="gold"
                    />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Full-width Address Section */}
        <div className="md:col-span-2">
          <Card>
            <CardContent className="pt-6">
              <AddressForm
                address={data.address}
                onAddressChange={(address) => setData('address', address)}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    </EditorDialog>
  );
}

export default TournamentForm;
