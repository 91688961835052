import {useState} from 'react';
import { usePage } from '@inertiajs/react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/shadcn/select";

interface Region {
  code: string;
  name: string;
  country_flag: string;
  slug: string;
}

interface PageProps {
  current_region: Region;
  regions: Region[];
}

const RegionSelector = () => {
  const { current_region, regions } = usePage<PageProps>().props;
  const [isOpen, setIsOpen] = useState(false);

  const handleRegionChange = (value: string) => {
    // Redirect to the new region
    window.location.href = `/${value}`;
  };

  return (
    <div className="relative">
      <Select defaultValue={current_region.code}
              onValueChange={handleRegionChange}
              onOpenChange={(open) => setIsOpen(open)}
      >
        <SelectTrigger className="w-[60px] md:w-[180px] text-warhammer-gold">
          <SelectValue placeholder="Select a region">
            <span className="flex items-center">
              <span className="mr-2 text-2xl">{current_region.country_flag}</span>
              <span className={`text-foreground ${isOpen ? '' : 'hidden md:inline'}`}>
                {current_region.name}
              </span>
            </span>
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          {regions.map((region) => (
            <SelectItem key={region.code} value={region.slug}>
              <span className="flex items-center cursor-pointer">
                <span className="mr-2 text-2xl">{region.country_flag}</span>
                <span className="text-foreground">{region.name}</span>
              </span>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default RegionSelector;
