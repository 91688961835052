import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/shadcn/table";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/shadcn/card";
import FormattedDate from "@/components/ui/FormattedDate";
import { TournamentLink } from "@/components/ui/tournaments/TournamentLink";

interface TournamentResult {
  id: number;
  rank: number;
  tournament: {
    id: number;
    name: string;
    start_date: string;
    end_date?: string;
    url: string;
  };
  faction?: {
    name: string;
  };
  wins: number;
  losses: number;
  draws: number;
  ranking_points: number;
}

interface PlayerTournamentResultsTableProps {
  results: TournamentResult[];
}

export function PlayerTournamentResultsTableCard({ results }: PlayerTournamentResultsTableProps) {
  const getRankEmoji = (rank: number) => {
    if (rank === 1) return '🏆';
    if (rank === 2) return '🥈';
    if (rank === 3) return '🥉';
    return `${rank}`;
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Tournament History</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Date</TableHead>
              <TableHead>Tournament</TableHead>
              <TableHead>Faction</TableHead>
              <TableHead className="text-right">Rank</TableHead>
              <TableHead className="text-right">W/L/D</TableHead>
              <TableHead className="text-right">Points</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {results.map((result) => (
              <TableRow key={result.id}>
                <TableCell>
                  <FormattedDate
                    startDate={result.tournament.start_date}
                    endDate={result.tournament.end_date}
                  />
                </TableCell>
                <TableCell>
                  <TournamentLink tournament={result.tournament} />
                </TableCell>
                <TableCell>{result.faction?.name || 'Unknown'}</TableCell>
                <TableCell className="text-right">
                  {getRankEmoji(result.rank)}
                </TableCell>
                <TableCell className="text-right font-mono">
                  <span className="text-green-600">{result.wins}</span>/
                  <span className="text-red-600">{result.losses}</span>/
                  <span className="text-orange-600">{result.draws}</span>
                </TableCell>
                <TableCell className="text-right font-mono">
                  {result.ranking_points}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
