import newRecruitLogo from '@/assets/images/newrecruit_logo.webp';
import bcpLogo from '@/assets/images/bcp_logo.png';
import statsAndLaddersLogo from '@/assets/images/SL_LogoLive.png';

export const PLATFORM_CONFIGS = {
  'tp-new-recruit': {
    logo: newRecruitLogo,
    logoClass: 'h-5 w-5 rounded-full object-cover bg-white',
    displayName: 'New Recruit'
  },
  'tp-bcp': {
    logo: bcpLogo,
    logoClass: 'h-5 w-5 rounded-full object-cover bg-white',
    displayName: 'BCP'
  },
  'tp-stats-and-ladders': {
    logo: statsAndLaddersLogo,
    logoClass: 'h-5 w-5 rounded-full object-cover bg-white',
    displayName: 'Stats & Ladders'
  },
} as const;

export type PlatformIdentifier = keyof typeof PLATFORM_CONFIGS;
